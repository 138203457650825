import { factory, primaryKey } from "@mswjs/data";
import { number } from "zod";

export const mockClassFactory = factory({
  class: {
    id: primaryKey(Number),
    name: String,
    subject: String,
  },
  statistics: {
    id: primaryKey(Number),
    student_id: Number,
    accomplished_skills_percentage: Number,
    class_average_accomplished_skills_percentage: Number,
    skill_accomplishment_percentage_according_to_class_average: Number,
    respective: String,
  },
  rubric: {
    id: primaryKey(Number),
    code: String,
    subject: Object,
    educationLevel: Object,
    cohort: Object,
    goalsCount: Number,
  },
  goals: {
    id: primaryKey(Number),
    education_level_id: Number,
    label: String,
    color: String,
    goals: Array,
  },
  periods: {
    id: primaryKey(Number),
    name: String,
    school_year_id: Number,
    from_date: String,
    to_date: String,
  },
  students: {
    id: primaryKey(Number),
    given_name: String,
    addition: String,
    family_name: String,
    display_name: String,
    education_level_id: Number,
  },
});

// Seed classes
export const classData = [
  {
    id: 1,
    name: "1A",
    subject: "Wiskunde",
  },
  {
    id: 2,
    name: "1B",
    subject: "Wiskunde",
  },
  {
    id: 3,
    name: "1C",
    subject: "Wiskunde",
  },
  {
    id: 4,
    name: "1D",
    subject: "Wiskunde",
  },
  {
    id: 5,
    name: "1E",
    subject: "Wiskunde",
  },
  {
    id: 6,
    name: "1F",
    subject: "Wiskunde",
  },
  {
    id: 7,
    name: "1G",
    subject: "Wiskunde",
  },
  {
    id: 8,
    name: "1H",
    subject: "Wiskunde",
  },
  {
    id: 9,
    name: "1I",
    subject: "Wiskunde",
  },
  {
    id: 10,
    name: "1J",
    subject: "Wiskunde",
  },
  {
    id: 11,
    name: "1K",
    subject: "Wiskunde",
  },
  {
    id: 12,
    name: "1L",
    subject: "Wiskunde",
  },
  {
    id: 13,
    name: "1M",
    subject: "Wiskunde",
  },
  {
    id: 14,
    name: "1N",
    subject: "Wiskunde",
  },
  {
    id: 15,
    name: "1O",
    subject: "Wiskunde",
  },
  {
    id: 16,
    name: "1P",
    subject: "Wiskunde",
  },
  {
    id: 17,
    name: "1Q",
    subject: "Wiskunde",
  },
  {
    id: 18,
    name: "1R",
    subject: "Wiskunde",
  },
  {
    id: 19,
    name: "1S",
    subject: "Wiskunde",
  },
  {
    id: 20,
    name: "1T",
    subject: "Wiskunde",
  },
];

export const classGoalsData = [
  {
    id: 1,
    education_level_id: 1,
    label: "Groen",
    color: "#A0CB74",
    goals: [
      {
        id: 1,
        label: "Informatieverwerking en statistiek",
        description: "Informatieverwerking en statistiek",
        order: 1,
        sub_goals: [
          {
            id: 16,
            label: "Centrummaten",
            description: "",
            order: 1,
          },
          {
            id: 17,
            label: "Diagrammen",
            description: "",
            order: 2,
          },
          {
            id: 18,
            label: "Telproblemen",
            description: "",
            order: 3,
          },
          {
            id: 19,
            label: "Kans",
            description: "",
            order: 4,
          },
        ],
      },
      {
        id: 2,
        label: "Vlakke meetkunde",
        description: "Vlakke meetkunde",
        order: 2,
        sub_goals: [
          {
            id: 21,
            label: "Hoeken in vlakke figuren",
            description: "",
            order: 1,
          },
          {
            id: 22,
            label: "Eigenschappen vlakke figuren",
            description: "",
            order: 2,
          },
          {
            id: 23,
            label: "Lijnsymmetrie",
            description: "",
            order: 3,
          },
          {
            id: 24,
            label: "Oppervlakte en omtrek",
            description: "",
            order: 4,
          },
        ],
      },
      {
        id: 3,
        label: "Ruimte meetkunde",
        description: "Ruimte meetkunde",
        order: 3,
        sub_goals: [
          {
            id: 26,
            label: "Eigenschappen ruimtefiguren",
            description: "",
            order: 1,
          },
          {
            id: 27,
            label: "Inhoud en oppervlakte",
            description: "",
            order: 2,
          },
          {
            id: 28,
            label: "Aanzichten",
            description: "",
            order: 3,
          },
          {
            id: 29,
            label: "Doorsneden",
            description: "",
            order: 4,
          },
          {
            id: 30,
            label: "Kijklijnen en kijkhoek",
            description: "",
            order: 5,
          },
        ],
      },
    ],
  },
];

export const classStatisticsData = [
  {
    id: 1,
    student_id: 2,
    accomplished_skills_percentage: 70,
    class_average_accomplished_skills_percentage: 30,
    skill_accomplishment_percentage_according_to_class_average: 200,
    respective: "ahead",
  },
  {
    id: 2,
    student_id: 3,
    accomplished_skills_percentage: 30,
    class_average_accomplished_skills_percentage: 30,
    skill_accomplishment_percentage_according_to_class_average: 100,
    respective: "average",
  },
  {
    id: 3,
    student_id: 4,
    accomplished_skills_percentage: 10,
    class_average_accomplished_skills_percentage: 30,
    skill_accomplishment_percentage_according_to_class_average: 20,
    respective: "behind",
  },
];

export const classPeriodsData = [
  {
    id: 1,
    name: "Leerjaar 1",
    school_year_id: 1,
    from_date: "2022-09-12",
    to_date: "2023-09-12",
  },
  {
    id: 2,
    name: "Leerjaar 2",
    school_year_id: 1,
    from_date: "2023-09-12",
    to_date: "2024-09-12",
  },
  {
    id: 3,
    name: "Leerjaar 3",
    school_year_id: 1,
    from_date: "2024-09-12",
    to_date: "2025-03-12",
  },
];

export const classStudentsData = [
  {
    id: 1,
    given_name: "Daan",
    addition: "",
    family_name: "Beek",
    display_name: "Daan Beek",
    education_level_id: 1,
  },
  {
    id: 2,
    given_name: "Maud",
    addition: "",
    family_name: "Ruiter",
    display_name: "Maud Ruiter",
    education_level_id: 1,
  },
  {
    id: 3,
    given_name: "Thijs",
    addition: "",
    family_name: "Dekker",
    display_name: "Thijs Dekker",
    education_level_id: 1,
  },
  {
    id: 4,
    given_name: "Anna",
    addition: "",
    family_name: "Beek",
    display_name: "Anna Beek",
    education_level_id: 1,
  },
];
