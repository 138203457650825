import { SVGProps } from "react";
const SvgChevronUpDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 253.741 106.477 C 256.293 106.477 258.675 106.877 260.885 107.677 C 263.113 108.459 265.171 109.811 267.077 111.717 L 355.192 199.832 C 358.698 203.318 360.354 207.7 360.184 212.94 C 360.03 218.18 358.222 222.542 354.716 226.028 C 351.23 229.514 346.79 231.268 341.378 231.268 C 335.988 231.268 331.548 229.514 328.043 226.028 L 253.741 151.727 L 178.962 226.504 C 175.476 229.992 171.112 231.668 165.854 231.515 C 160.634 231.344 156.27 229.514 152.764 226.028 C 149.278 222.542 147.526 218.084 147.526 212.692 C 147.526 207.3 149.278 202.842 152.764 199.356 L 240.403 111.717 C 242.309 109.811 244.385 108.459 246.595 107.677 C 248.825 106.877 251.207 106.477 253.741 106.477 Z"
      fill="currentColor"
    />
    <path
      d="M 253.981 393.256 C 251.428 393.256 249.048 392.856 246.837 392.056 C 244.607 391.274 242.549 389.922 240.645 388.016 L 152.53 299.901 C 149.025 296.415 147.366 292.053 147.538 286.793 C 147.69 281.573 149.501 277.211 153.006 273.705 C 156.492 270.219 160.932 268.465 166.342 268.465 C 171.734 268.465 176.172 270.219 179.678 273.705 L 253.981 348.007 L 328.76 273.229 C 332.247 269.743 336.609 268.066 341.868 268.218 C 347.088 268.389 351.45 270.219 354.956 273.705 C 358.442 277.211 360.196 281.649 360.196 287.041 C 360.196 292.453 358.442 296.891 354.956 300.377 L 267.317 388.016 C 265.413 389.922 263.335 391.274 261.125 392.056 C 258.897 392.856 256.515 393.256 253.981 393.256 Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgChevronUpDown;
